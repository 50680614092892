// Slice of store that manages Socket connections
import { createSlice, createAction } from '@reduxjs/toolkit';
// import { auth } from '../../../firebase';
import { setFirebaseJWT } from '../user/userSlice';
// import { initializeSocketConnection } from '../../socketEvents';

const initialState = {
  isConnected: false,
  incomingTxData: {},
  outgoingTxData: {},
  poolTXData: {},
  notifications: {
    page: 1,
    limit: 10,
    totalItems: 0,
    unreadCount: 0,
    messages: {},
    hasMore: false,
  },
  messages: {},
  currentOrder: null,
};

// Now create the slice
const socketSlice = createSlice({
  name: 'socket',
  initialState,
  // Reducers: Functions we can call on the store
  reducers: {
    connectionEstablished: (state) => {
      state.isConnected = true;
    },
    connectionLost: (state) => {
      state.isConnected = false;
    },
    update_incomingOrders: (state, action) => {
      state.incomingTxData = action.payload;
    },
    update_outgoingOrders: (state, action) => {
      // console.log('jsaljsal', action.payload);
      state.outgoingTxData = action.payload;
    },
    update_poolOrders: (state, action) => {
      state.poolTXData = action.payload;
    },
    update_notifications: (state, action) => {
      (state.notifications.page = action.payload.page),
        (state.notifications.limit = action.payload.limit),
        (state.notifications.totalItems = action.payload.totalItems),
        (state.notifications.unreadCount = action.payload.number),
        (state.notifications.messages = action.payload.data);
      state.notifications.hasMore =
        action.payload.page * action.payload.limit < action.payload.totalItems;
    },

    update_messages: (state, action) => {
      state.messages[action.payload?.txId] = action.payload.data;
    },
    update_current_order: (state, action) => {
      state.currentOrder = action.payload;
    },
    resetSockets: () => initialState,
  },
});

// Don't have to define actions, they are automatically generated
export const {
  initSocket,
  connectionEstablished,
  connectionLost,
  update_incomingOrders,
  update_outgoingOrders,
  update_poolOrders,
  update_messages,
  update_current_order,
  update_notifications,
  resetSockets,
} = socketSlice.actions;
// Export the reducer for this slice
export const updateNewConnection = () => {
      return async (dispatch) => {
            const  jwt =  await auth.currentUser.getIdToken(true);
            dispatch(setFirebaseJWT(jwt))
            // dispatch(initializeSocketConnection())
      };
    };
export const send_message = createAction('socket/send_message');
export const tx_messages = createAction('socket/tx_messages'); // subsribe to tx and messages
export const get_notifictaions = createAction('socket/notify');

export const selectOrders = (state) => ({
  pool: state.socket.poolTXData,
  incoming: state.socket.incomingTxData,
  outgoing: state.socket.outgoingTxData,
});

export const selectSocket = (state) => state.socket;
export const selectNotifications = (state) => state.socket.notifications;
export const selectmessages = (tx) => (state) => state.socket.messages[tx];

export default socketSlice.reducer;
