import React from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import Icon from '../../components/Icon';
import ResponsiveImage from '../../components/ResponsiveImage';
import usePictures from '../../hooks/usePictures';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';

const imgs = usePictures(
  require.context('../../assets/img/p-careers', false, /\.(png|jpe?g|svg|webp)$/)
);

const Contact = () => {
  useBodyClass('p-contact');
  React.useEffect(() => {
      document.title = 'Contact Pesabase: We’re Here to Help You';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'This is an awesome React app2'
      // document
      //   .querySelector('meta[name="description"]')
      //   .setAttribute('content', "Have questions? We're here for you! Contact Pesabase for any support or inquiries about our remittance services in South Sudan. Your connection matters!");
      // document
      //   .querySelector('meta[name="title"]')
      //   .setAttribute('content', 'Contact Pesabase: We’re Here to Help You');
    }, []);

  return (
    <main>
      <Helmet>
            <meta itemProp="title" content="Contact Pesabase: We’re Here to Help You" />
            <meta itemProp="description" content="Have questions? We're here for you! Contact Pesabase for any support or inquiries about our remittance services in South Sudan. Your connection matters!" />
            <meta name="description" content="Have questions? We're here for you! Contact Pesabase for any support or inquiries about our remittance services in South Sudan. Your connection matters!" />


      </Helmet> 
      <Header />
      <section className='s-hero'>
        <Container disableGutters>
          <div className='s-hero-top'>
            <div className='s-hero-top-desc'>
              <span className='bg bg-parent'>
                <span className='bg--layout'></span>

                <ResponsiveImage
                  desktop={imgs['s-hero@desktop.jpg']}
                  tablet={imgs['s-hero@tablet.jpg']}
                  mobile={imgs['s-hero@mobile.jpg']}
                  type={'jpg'}
                  alt='section hero'
                />
              </span>

              <h1>Get in touch</h1>

              <div className='s-hero-top-desc-bottom'>
                <span className='subtitle'>
                  Feel free to contact us on your enquiries and we'll get back to you as soon as
                  possible.
                </span>

                <a href='mailto:team@pesabase.com'>team@pesabase.com</a>
              </div>
            </div>

            <div className='s-hero-top-office'>
              <h2>Our offices</h2>

              <div className='s-hero-top-office-el'>
                <h6>Kenya</h6>

                <a
                  href='https://www.google.com/maps/place/Old+Mutual+Tower/@-1.2991378,36.8166702,17z/data=!3m1!4b1!4m5!3m4!1s0x182f10e400e852b7:0xae4857eea7d6651!8m2!3d-1.2991378!4d36.8188589'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='geo' />
                  Upperhill Nairobi, Lower Hill Duplex next to Old Mutal Tower, office no.52
                </a>

                <a href='tel:+61491208116'>
                  <Icon name='phone' />
                  +61491208116
                </a>
              </div>

              <div className='s-hero-top-office-el'>
                <h6>South Sudan</h6>

                <a
                  href='https://www.google.com/maps?q=Juba+Town,+Jamous+Building,+1st+floor,+office+no.5&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjxnbL174r8AhXO_CoKHSDpDRIQ_AUoAXoECAEQAw'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='geo' />
                  Juba Town, Jamous Building, 1st floor, office no.5
                </a>

                <a className='phone' href='tel:+211924440388'>
                  <Icon name='phone' />
                  +211924440388
                </a>

                <a className='phone' href='tel:+211924440388'>
                  <Icon name='phone' />
                  +211917523741
                </a>

                <a className='phone' href='tel:+211924440388'>
                  <Icon name='phone' />
                  +211927750001
                </a>
              </div>

              <div className='s-hero-top-office-el'>
                <h6>Australia</h6>

                <a
                  href='https://www.google.com/maps/place/Keilor+Downs+VIC+3038,+Australia/@-37.7204532,144.7859253,14z/data=!3m1!4b1!4m5!3m4!1s0x6ad65f41dabb9e81:0x5045675218cd2f0!8m2!3d-37.7233543!4d144.8077931'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon name='geo' />
                  Keilor DownsMelbourne, Australia.
                </a>

                <a href='tel:+61491208116'>
                  <Icon name='phone' />
                  +61491208116
                </a>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </main>
  );
};

export default Contact;
