import React from 'react';
import { Link } from 'react-router-dom';
import eyeLogo from '../../assets/icons/lit-logo.gif';
import Icon from '..//Icon';
import google from '../../assets/img/p-home/google.png';
import apple from '../../assets/img/p-home/apple.png';
import { gsap } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import ResponsiveImage from '..//ResponsiveImage';
import usePictures from '../../hooks/usePictures';
import { Container } from '@mui/material';

gsap.registerPlugin(ScrollToPlugin);

const Footer = () => {
  const imgs = usePictures(
    require.context('../../assets/img/c-footer', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const ulAnim = (target, parent) => {
    gsap
      .timeline({})
      .to(target, { height: target.offsetHeight === 0 ? 'auto' : 0 })
      .to(
        parent.querySelector('.icon-dropdown'),
        { rotate: target.offsetHeight === 0 ? 90 : 0 },
        '<'
      );
  };

  const listAnim = (e) => {
    if (window.innerWidth <= 640) {
      const target = e.currentTarget.querySelector('ul');
      ulAnim(target, e.currentTarget);
    }
  };

  const scrollUp = () => gsap.timeline({}).to(window, { duration: 0.6, scrollTo: 0 });

  return (
    <>
      <footer className='c-footer'>
        <span className='c-footer--bg'>
          <ResponsiveImage
            desktop={imgs['footer@desktop.jpg']}
            tablet={imgs['footer@tablet.jpg']}
            mobile={imgs['footer@mobile.jpg']}
            type={'jpg'}
            alt='Footer'
          />
        </span>
        <Container disableGutters>
          <div className='c-footer-top'>
            <div className='c-footer-top-desc'>
              <h6 className='el-title--h6 c-footer-top-desc--title'>
                Pesabase is the easiest way for you to send money and make payments. We make
                transfers to your friends and family instantly.
              </h6>

              <span className='c-footer-top-desc--subtitle'>
                Feel free to contact us on your enquiries and we will get back to you as soon as
                possible.
              </span>

              <a href='mailto:team@pesabase.com' target={'_blank'} rel='noreferrer'>
                team@pesabase.com
              </a>
            </div>

            <div className='c-footer-top-links'>
              <div className='c-footer-top-links-inner'>
                <div className='links-list' onClick={(e) => listAnim(e)}>
                  <div className='el-title--h6 links-list--title'>
                    Company
                    <Icon name='dropdown' />
                  </div>

                  <ul>
                    <li>
                      <Link to={'/account'}>Home</Link>
                    </li>

                    <li>
                      <Link to={'/about'}>About us</Link>
                    </li>

                    <li>
                      <Link><a target='_blank' rel='noopener noreferrer' href={'https://pesatoken.io/'}>Pesa Token</a> </Link>
                    </li>

                    <li>
                      <Link to={'/blog'}>Blog</Link>
                    </li>
                    <li>
                      <Link to={'/faq'}>Faq</Link>
                    </li>
                    <li>
                      <Link to={'/careers'}>Careers</Link>
                    </li>

                    <li>
                      <Link to={'/contact'}>Contact us</Link>
                    </li>
                  </ul>
                </div>

                <div className='links-list' onClick={(e) => listAnim(e)}>
                  <div className='el-title--h6 links-list--title'>
                    Info
                    <Icon name='dropdown' />
                  </div>

                  <ul>
                    <li>
                      <Link to={'/referral'}>Referral program</Link>
                    </li>

                    <li>
                      <Link to={'/rewards'}>Rewards program</Link>
                    </li>
                    
                    <li>
                      <Link to={'/terms'}>Terms and Conditions</Link>
                    </li>

                    <li>
                      <Link to={'/privacy'}>Privacy Policy</Link>
                    </li>

                    <li>
                      <Link to={'/cookies'}>Cookies Policy</Link>
                    </li>
                  </ul>
                </div>

                <div className='links-list' onClick={(e) => listAnim(e)}>
                  <div className='el-title--h6 links-list--title'>
                    Social
                    <Icon name='dropdown' />
                  </div>

                  <ul>
                    <li>
                      <Link to={'https://www.facebook.com/pesabase/'}>Facebook</Link>
                    </li>

                    <li>
                      <Link to={'https://twitter.com/PESA_token'}>Twitter</Link>
                    </li>

                    <li>
                      <Link to={'https://www.linkedin.com/company/pesabaseltd/'}>LinkedIn</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='links-store'>
                <h6 className='links-store--title'>Get The App</h6>

                <div className='links-store--items'>
                  <a
                    href='https://play.google.com/store/apps/details?id=com.pesabase.app&hl=en'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={google} alt='' />
                  </a>

                  <a
                    href='https://apps.apple.com/us/app/pesabase/id1557420579'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img src={apple} alt='' />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className='c-footer-bottom'>
            <span className='publish'>{`© ${new Date().getFullYear()} Pesabase`}</span>

          

            

            <span className='scrollup' onClick={scrollUp}>
              <Icon name='arrow-slider-black' />
            </span>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
