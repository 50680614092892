import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import AccountFooter from '../AccFooter';
import Header from '../Header';
import { Container } from '@mui/material';
import AgentSideBar from '../../agent/components/AgentSideBar';
import UserSideBar from '../../user/components/UsersSideBar';
import useIsMobile from '../../hooks/useIsMobile';

const Layout = ({ type, agent, user }) => {
  const location = useLocation();
  const isAgentChatPage = location.pathname === '/agent/chat';
  const isMobile = useIsMobile();

  if (type === 'auth') {
    return (
      <>
        <Header auth={true} />

        <div className='auth-content'>
          <Outlet />
        </div>
      </>
    );
  }

  if (type === 'acc' || type === undefined) {
    return (
      <div className={`p-account`}>
        <>
          <Header account={true} />

          {!isMobile && (agent ? <AgentSideBar /> : <UserSideBar />)}

          <div className='c-acc-content'>
            <Container disableGutters className='c-acc-container'>
              <Outlet />
            </Container>
            <AccountFooter />
          </div>
        </>
      </div>
    );
  }

  if (type === 'pool') {
    return (
      <div className={`p-account without`}>
        <>
          <Header account={false} chat={true} />
          <div className='c-acc-content without'>
            <Container disableGutters className='c-acc-container'>
              <Outlet />
            </Container>
          </div>
        </>
      </div>
    );
  }

  if (type === 'chat') {
    return (
      <div className={`p-account ${isAgentChatPage ? 'p-account-chat' : ''}`}>
        <>
          <Header account={false} chat={true} />

          <Outlet />
        </>
      </div>
    );
  }

  if (type === 'tx') {
    return (
      <div className='p-account without'>
        <Header payment={true} />

        <div className='c-acc-content without'>
          <Container disableGutters className='c-760'>
            <Outlet />
          </Container>
        </div>
      </div>
    );
  }

  if (type === 'tx-single') {
    return (
      <div className='p-account without'>
        <Header account={true} />

        <div className='c-acc-content without '>
          <Container disableGutters className='c-tx-single'>
            <Outlet />
          </Container>
        </div>
      </div>
    );
  }
};

Layout.propTypes = {
  children: PropTypes.object,
  type: PropTypes.string.isRequired,
  agent: PropTypes.bool.isRequired,
  user: PropTypes.bool.isRequired,
};

export default Layout;
