import React, { useState } from 'react';
import usePictures from '../../../hooks/usePictures';
import useLowerCase from '../../../hooks/useLowerCase';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import Skeleton from '@mui/material/Skeleton';
import Avatar from '../../../components/Avatar';
import transformString from '../../../utils/transformString';

const UserTxItem = ({ tx = {}, userUid, txLink = false, loading }) => {
  const [txDetails, setTxDetails] = useState(false);
  const [rewardsPopUp, setRewardsPopUp] = useState(false);

  const icons = usePictures(
    require.context('../../../assets/icons', false, /\.(png|jpe?g|svg|webp)$/)
  );

  const imgs = usePictures(
    require.context('../../../assets/img/p-dashboard', false, /\.(png|jpe?g|svg|webp)$/)
  );
  const toggleTxDetails = () => setTxDetails(!txDetails);
  const toggleRewards = () => setRewardsPopUp(!rewardsPopUp);
  const txPaymentSystem =
    tx && tx?.paymentSystem !== undefined ? useLowerCase(tx?.paymentSystem) : '';
  const txOperation = tx?.operation !== undefined ? useLowerCase(tx?.operation) : '';
  // console.log('tx ',tx);
  const txItemContent = (
    <>
      <div className='tx-item-info'>
        <div className='tx-item-info-avatar'>
          <>
            {txOperation === 'crypto deposit' ? (
              <>
                <img src={imgs['avatar-cripto@2x.png']} alt='' />
              </>
            ) : txOperation === 'swap' ? (
              <>
                <img src={imgs['avatar-cripto@2x.png']} alt='' />
              </>
            ) : txOperation === 'transfer' ? (
              <Avatar data={tx?.sender?.uid !== userUid ? tx?.sender : tx?.receiver} />
            ) : txOperation === 'withdrawal' ? (
              <>
                {txPaymentSystem === 'm-pesa' ? (
                  <img src={imgs['avatar-mpesa-agent@2x.png']} alt='' />
                ) : (
                  <img src={imgs['avatar-cripto@2x.png']} alt='' />
                )}
              </>
            ) : txOperation === 'top up' ? (
              <Avatar data={tx?.sender} />
            ) : txOperation === 'referral reward' ? (
              <>
                <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
              </>
            ) : txOperation === 'signup reward' ? (
              <>
                <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
              </>
            ) : txOperation === 'remit' ? (
              <>
                <img src={imgs['avatar-pesa-ref@2x.png']} alt='' />
              </>
            ) : (
              <Avatar data={tx?.sender} />
            )}
          </>
        </div>

        <div className='tx-item-info-desc'>
          <div className='tx-item-info-name'>
            <div className='tx-item-info-name-title '>
              {txOperation === 'crypto deposit' ? (
                <>Crypto deposit</>
              ) : txOperation === 'swap' ? (
                <>
                  {tx?.sender?.name && transformString(tx?.sender?.name) !== ' '
                    ? tx?.sender?.name
                    : 'Buy PESA'}
                </>
              ) : txOperation === 'transfer' ? (
                <>
                  {tx?.sender?.uid !== userUid ? (
                    <>
                      {tx?.sender?.name && transformString(tx?.sender?.name) !== ' '
                        ? tx?.sender?.name
                        : 'Pesabase user'}
                    </>
                  ) : (
                    <>
                      {tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' '
                        ? tx?.receiver?.name
                        : 'Pesabase user'}
                    </>
                  )}
                </>
              ) : txOperation === 'withdrawal' ? (
                txPaymentSystem === 'm-pesa' ? (
                  <>
                    {tx?.receiver?.name && transformString(tx?.receiver?.name) !== ' '
                      ? tx?.receiver.name
                      : tx?.receiver?.phoneNumber}
                  </>
                ) : (
                  <>Withdrawal</>
                )
              ) : txOperation === 'top up' ? (
                <>
                  {tx?.sender?.name && transformString(tx?.sender?.name) !== ' '
                    ? tx?.sender.name
                    : 'Pesabase user'}
                </>
              ) : txOperation === 'referral reward' ? (
                <>Referral reward</>
              ) : txOperation === 'signup reward' ? (
                <>Welcome reward</>
              ) : txOperation === 'remit' ? (
                <>Remit</>
              ) : (
                <></>
              )}
            </div>
            {tx?.sender?.uid !== userUid ? (
              <>
                {txOperation !== 'swap' ? (
                  <>
                    {tx?.sender?.agent && <span className='tx-item-info-agent'>Agent</span>}
                    {tx?.sender?.favourite && (
                      <img className='tx-item-info-star' src={icons['icon-star.svg']} alt='' />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
                {txOperation !== 'swap' ? (
                  <>
                    {tx?.receiver?.agent && <span className='tx-item-info-agent'>Agent</span>}
                    {tx?.receiver?.favourite && (
                      <img className='tx-item-info-star' src={icons['icon-star.svg']} alt='' />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
          {tx?.timestamp && (
            <div className='tx-item-info-date'>
              {dayjs(parseInt(tx?.timestamp)).format('DD MMM, HH:mm')}
            </div>
          )}
        </div>
      </div>

      <div className='tx-item-quantity'>
        <div
          className={`tx-item-quantity-wrap ${
            tx?.sender?.agent
              ? 'wallet-quan'
              : txOperation === 'crypto deposit'
                ? 'wallet-quan'
                : txOperation === 'swap'
                  ? 'buy-quan'
                  : txOperation === 'top up'
                    ? 'wallet-quan'
                    : txOperation === 'withdrawal' && txPaymentSystem !== 'm-pesa'
                      ? 'withdrawal-quan'
                      : tx?.sender?.uid === userUid
                        ? 'sent-quan'
                        : 'received-quan'
          }`}
        >
          <div className='icon'>
            {tx?.sender?.uid === userUid ? (
              <>
                {txOperation === 'swap' ? (
                  <>
                    <img src={icons['icon-type-buy.svg']} alt='' />
                  </>
                ) : txOperation === 'withdrawal' ? (
                  <>
                    {txPaymentSystem === 'm-pesa' ? (
                      <img src={icons['icon-sent.svg']} alt='' />
                    ) : (
                      <img src={icons['icon-type-withdrawal.svg']} alt='' />
                    )}
                  </>
                ) : (
                  <img src={icons['icon-sent.svg']} alt='' />
                )}
              </>
            ) : tx?.sender?.agent ? (
              <>
                {tx?.sender?.agent ? (
                  <>
                    <img src={icons['icon-wallet.svg']} alt='' />
                  </>
                ) : (
                  <></>
                )}
              </>
            ) : txOperation === 'crypto deposit' ? (
              <>
                <img src={icons['icon-wallet.svg']} alt='' />
              </>
            ) : txOperation === 'swap' ? (
              <>
                <img src={icons['icon-type-buy.svg']} alt='' />
              </>
            ) : txOperation === 'transfer' ? (
              <>
                <img src={icons['icon-received.svg']} alt='' />
              </>
            ) : txOperation === 'withdrawal' ? (
              <>
                {txPaymentSystem === 'm-pesa' ? (
                  <img src={icons['icon-sent.svg']} alt='' />
                ) : (
                  <img src={icons['icon-type-withdrawal.svg']} alt='' />
                )}
              </>
            ) : txOperation === 'top up' ? (
              <>
                <img src={icons['icon-wallet.svg']} alt='' />
              </>
            ) : txOperation === 'referral reward' ? (
              <>
                <img src={icons['icon-received.svg']} alt='' />
              </>
            ) : txOperation === 'signup reward' ? (
              <>
                <img src={icons['icon-received.svg']} alt='' />
              </>
            ) : (
              <>
                <img src={icons['icon-sent.svg']} alt='' />
              </>
            )}
          </div>

          {tx?.currency === 'USD' ? (
            <div className='text'>$ {parseFloat(tx?.amount).toFixed(2)}</div>
          ) : (
            <div className='text'>
              {parseFloat(tx?.amount).toFixed(2)} {tx?.currency}
            </div>
          )}
        </div>
      </div>

      <div className='tx-item-status'>
        {tx?.status === 'completed' ? (
          <div className='tx-item-status-wrap success'>
            <div className='icon'>
              <img src={icons['icon-success.svg']} alt='' />
            </div>
            <div className='text'>completed</div>
          </div>
        ) : tx?.status === 'cancelled' ? (
          <div className='tx-item-status-wrap failed'>
            <div className='icon'>
              <img src={icons['icon-failed.svg']} alt='' />
            </div>
            <div className='text'>cancelled</div>
          </div>
        ) : tx?.status === 'failed' ? (
          <div className='tx-item-status-wrap failed'>
            <div className='icon'>
              <img src={icons['icon-failed.svg']} alt='' />
            </div>
            <div className='text'>failed</div>
          </div>
        ) : tx?.status === 'initiated' ? (
          <div className='tx-item-status-wrap initiated'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>initiated</div>
          </div>
        ) : tx?.status === 'assigned' ? (
          <div className='tx-item-status-wrap assigned'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>assigned</div>
          </div>
        ) : tx?.status === 'pending' ? (
          <div className='tx-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>pending</div>
          </div>
        ) : tx?.status === 'processing' ? (
          <div className='tx-item-status-wrap pending'>
            <div className='icon'>
              <img src={icons['icon-time.svg']} alt='' />
            </div>
            <div className='text'>processing</div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <div className='tx-item-details'>
        <span className='tx-item-details-btn'>
          <img src={icons['icon-arrow-right.svg']} alt='' />
        </span>
      </div>
    </>
  );
  return (
    <div className='tx-item'>
      {loading ? (
        <div className='tx-item-wrap'>
          <Skeleton animation='wave' variant='rectangular' />
        </div>
      ) : (
        <>
          {txLink ? (
            <>
              <Link className={`tx-item-wrap link`} to={`/user/transactions/${tx?.txId}`}>
                <>{txItemContent}</>
              </Link>
            </>
          ) : (
            <>
              <div className={`tx-item-wrap`} onClick={toggleTxDetails}>
                {txItemContent}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

UserTxItem.propTypes = {
  tx: PropTypes.object,
  onClick: PropTypes.func,
};

export default UserTxItem;
