/* eslint-disable react/jsx-no-target-blank */
import React, { useRef } from 'react';
import useBodyClass from '../../hooks/useBodyClass';
import AboutAnims from './AboutAnims';
import usePictures from '../../hooks/usePictures';
import ResponsiveImage from '../../components/ResponsiveImage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import { Container } from '@mui/material';



const About = () => {
  useBodyClass('p-about');
  const heroSection = useRef(null);
  const teamSection = useRef(null);
  const coloredText = useRef(null);

  const imgs = usePictures(
    require.context('../../assets/img/p-about', false, /\.(png|jpe?g|svg|webp)$/)
  );

  AboutAnims(heroSection, teamSection, coloredText);
  React.useEffect(() => {
      document.title = 'About Pesabase: Innovating Remittances in South Sudan';

    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = 'This is an awesome React app'
      // document
      //   .querySelector('meta[name="description"]')
      //   .setAttribute('content', 'Learn about Pesabase’s heartfelt mission to empower South Sudanese families, making remittances accessible and meaningful through blockchain technology.');
      // document
      //   .querySelector('meta[name="title"]')
      //   .setAttribute('content', 'About Pesabase: Innovating Remittances in South Sudan');
    }, []);

  return (
    <div>

      <Header />
      <section className='s-hero' ref={heroSection}>
        <span className='s-hero-bg bg-parent'>
          <ResponsiveImage
            desktop={imgs['s-hero@desktop.jpg']}
            tablet={imgs['s-hero@tablet.jpg']}
            mobile={imgs['s-hero@mobile.jpg']}
            type={'jpg'}
            alt='section hero'
          />
        </span>
        <Container disableGutters>
          <div className='s-hero-bottom'>
            <h1>
              We make transfers to your friends and family
              <span></span>
              <i></i>
            </h1>
          </div>
        </Container>
      </section>

      <section className='s-story'>
        <Container disableGutters>
          <h2>Our story</h2>

          <div className='s-story-img'>
            <ResponsiveImage
              desktop={imgs['s-story@desktop.jpg']}
              tablet={imgs['s-story@tablet.jpg']}
              mobile={imgs['s-story@mobile.jpg']}
              type={'jpg'}
              alt='section hero'
            />
          </div>

          <div className='s-story-desc'>
            <div className='s-story-desc-text'>
              <div className='s-story-desc-text--left'>
                <p>
                  Born in the midst of war and being forced to flee as a refugee, our founder
                  experienced first hand the devastation of such conflict.
                </p>

                <p>
                  Having lived in 5 different countries and eventually Australia, he would send
                  money home frequently, but always sought for a better way to do it because of the
                  high costs & delays incurred each time.
                </p>
              </div>

              <div className='s-story-desc-text--right'>
                <p>The advent of blockchain technology promised to make this possible.</p>

                <p>
                  Pesabase is built on this foundation with a mission to connect Africans
                  financially.
                </p>
              </div>
            </div>

            <div className='s-story-desc--author'>
              <h5>Nhial Majok</h5>
              <h6>Founder, Advisor</h6>
            </div>
          </div>
        </Container>
      </section>

      <section className='s-mission'>
        <Container disableGutters>
          <h3 ref={coloredText}>
            Our mission is to financially connect Africa leveraging the power Blockchained based
            solutions like Stablecoins, crypto-currencies, mobile money.
          </h3>
        </Container>
      </section>

      <section className='s-team'>
        <div className='s-team-inner' ref={teamSection}>
          <div className='s-team-inner--bg'>
            <ResponsiveImage
              desktop={imgs['s-team@desktop.jpg']}
              tablet={imgs['s-team@tablet.jpg']}
              mobile={imgs['s-team@mobile.jpg']}
              type={'jpg'}
              alt='section team'
            />
          </div>
          <div className='s-team-desc'>
            <span className='s-team-desc--bg'></span>
            <h3>
              We at Pesabase believe that talent wins games, but teamwork and intelligence win
              championships.
            </h3>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default About;
