import React, { useEffect, useState } from 'react';
import animSuccess from '../../../assets/anims/videos/fullscreen/account-warn-loop.mp4';
import animFail from '../../../assets/anims/videos/fullscreen/account-fail-loop.mp4';
import { toast } from 'react-toastify';
import { verificationEmail } from '../../../api';
import { useSelector } from 'react-redux';
import { selectFirebaseJWT } from '../../../redux/features/user/userSlice';
import { Link, useParams } from 'react-router-dom';
import Success from '../../../components/Success/Success';

const VerificationEmail = () => {
  const userToken = useSelector(selectFirebaseJWT);
  const { token } = useParams();

  const [verification, setVerification] = useState({
    pending: true,
    error: false,
  });

  const { pending, error } = verification;

  useEffect(() => {
    (async () => {
      try {
        const response = await verificationEmail(userToken, token);
        if (response.status === 200) {
            window.location.href = "pesabase://";

        // Set a timeout to redirect to another page if the app fails to open
        setTimeout(function() {
        // Redirect to another page if the app is not opened
            setVerification((prev) => ({ ...prev, pending: false }));
        }, 2000); 
          
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        setVerification((prev) => ({ ...prev, error: true }));
        toast.error(`Something went wrong...${error?.message || 'Oops!'}`);
        console.log(error);
      }
    })();
  }, []);

  return !error ? (
    <Success
      video={animSuccess}
      title={pending ? 'Email Verification' : 'Email Verified!'}
      subtitle={
        pending
          ? `Please, wait just a moment while we verify your email address.`
          : `Congratulations, your email address has been successfully verified!`
      }
      subtitlebot={
        pending
          ? `We're ensuring the security of your account and making sure you have full access to our platform. This process should only take a few moments. Thank you for your patience!`
          : `Thank you for choosing us.`
      }
      buttons={
        <>
          {pending && (
            <Link className='el-button orange' to='/user/dashboard'>
              Go to Dashboard
            </Link>
          )}
        </>
      }
    />
  ) : (
    <Success
      video={animFail}
      title={'Email verification failed'}
      subtitle={`Something went wrong...`}
      buttons={
        <>
          {pending && (
            <Link className='el-button orange' to='/user/dashboard'>
              Go to Dashboard
            </Link>
          )}
        </>
      }
    />
  );
};

export default VerificationEmail;
